import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import { RadioButton } from "../../../components";

const Modal = function (props) {
  const {
    isOpen,
    handleClose,
    title,
    handleSubmit,
    // values,
    setValues,
    buttons,
    value,
    handleChangeOptionChange,
  } = props;

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">{title || "NA"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <RadioButton
              buttons={buttons}
              // value={values}
              setValue={setValues}
              value={value}
              handleChangeOptionChange={handleChangeOptionChange}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Modal;
