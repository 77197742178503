import { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { clearStorage, getItem } from "../../../services/localStorageService";
import { doLogin, send, setShowLoader, verifyOtp } from "../actions";

const selectLogin = ({ login }) => login;
const session = ({ session }) => session.userData;
const loader = ({ session }) => session.displayLoder;

const useSignIn = (props) => {
  const dispatch = useDispatch();
  const login = useSelector(selectLogin);
  const userData = useSelector(session);
  const loading = useSelector(loader);
  let scanId;
  let integrationId;

  const [authDetail, setAuthDetail] = useState({
    email: userData.email ? userData.email : "",
    password: "",
    isMailVerified: false,
  });

  const GA_TRACKING_ID = "UA-193680424-1";

  useEffect(() => {
    ReactGA.initialize(GA_TRACKING_ID);

    ReactGA.pageview("/session/signin");
  }, []);

  const [withOtp, setWithOtp] = useState(false);
  const [isResendingOtp, setIsResendingOtp] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState("not_getting");
  const [integration, setIntegration] = useState(undefined);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const redirectEmail = query.get("email");

  if (props.location.search) {
    scanId = props.location.search.substring(
      "?scanID=ddddd".indexOf("=") + 1,
      props.location.search.indexOf("&")
    );

    integrationId = props.location.search.substring(
      props.location.search.lastIndexOf("=") + 1
    );
  } else if (props.location && props.location.pathname.indexOf("scanID") >= 0) {
    scanId = props.location.pathname.substring(
      props.location.pathname.indexOf("=") + 1,
      props.location.pathname.indexOf("&")
    );
  }

  useEffect(() => {
    const userData = getItem("auth-user");
    setIntegration(
      props.history.location.state && props.history.location.state.integration
    );
    userData && userData.accessToken
      ? props.history.location.state &&
        props.history.location.state.integration &&
        props.history.location.state.integration.length > 0
        ? props.history.push({
            pathname: "/connectPage",
            state: { integration: props.history.location.state.integration },
          })
        : integrationId
        ? props.history.push({
            pathname: "/dashboard",
            state: { scanIdProps: scanId, integrationId },
          })
        : void 0
      : clearStorage();
  }, []);

  useEffect(() => {
    if (redirectEmail) {
      setAuthDetail((prevData) => ({ ...prevData, email: redirectEmail }));
    }
  }, []);

  const { email, password, isMailVerified } = authDetail;
  const { classes } = props;

  const handleChange = (event) => {
    event.persist();
    const auth = { ...authDetail, [event.target.name]: event.target.value };
    setAuthDetail(auth);
  };

  const resendOtp = () => {
    setIsResendingOtp(true);
    dispatch(doLogin(email, setWithOtp, setIsResendingOtp));
  };

  const handleFormSubmit = async () => {
    const selectedIntegration =
      (await props.history.location.state) &&
      props.history.location.state.integration;
    dispatch(setShowLoader());
    password
      ? dispatch(
          verifyOtp(
            email,
            password,
            props,
            redirectEmail ? undefined : scanId,
            integration,
            authDetail,
            setAuthDetail
          )
        )
      : dispatch(doLogin(email, setWithOtp, selectedIntegration));
    // this.props.loginWithEmailAndPassword({ ...this.state });
    setAuthDetail({ ...authDetail, isMailVerified: true });
  };

  const buttons = [
    {
      value: "not_getting",
      lable: "I am not getting Verification Code on my email ID",
    },
    {
      value: "does_not_work",
      lable: "Verification Code does not work",
    },
  ];
  const titles = {
    not_getting: "I am not getting Verification Code on my email ID",
    does_not_work: "Verification Code does not work",
  };

  const handleClose = () => {
    setIsOpen(!isOpen);
    setValue("not_getting");
  };

  const handleSubmit = () => {
    const payload = {
      subject: "Having difficulty to Sign In",
      description: titles[value],
      username: authDetail.email,
    };
    dispatch(send(payload, setValue, setIsOpen, setWithOtp));
  };

  const handleChangeOptionChange = (e) => {
    setValue(e.target.value);
  };

  return {
    handleChange,
    handleFormSubmit,
    email,
    password,
    isMailVerified,
    classes,
    withOtp,
    login,
    loading,
    resendOtp,
    isResendingOtp,
    setWithOtp,
    buttons,
    isOpen,
    handleClose,
    handleSubmit,
    value,
    handleChangeOptionChange,
  };
};

export default useSignIn;
