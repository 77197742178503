import React, { useEffect } from "react";
import clsx from "clsx";
import classes from "./Flex.module.css";
import PropTypes from "prop-types";

const Flex = React.forwardRef(
  (
    {
      direction = "row",
      justify = "",
      alignItems = "center",
      className,
      children,
      childSize = [],
      ...rest
    },
    ref
  ) => {
    useEffect(() => {
      childSize.forEach((size) => {
        const error = new Error(
          "Invalid element in childSize array, size must be a positive natural number and less than or equal to 12 or null"
        );
        if (typeof size !== "number") {
          if (size !== null) {
            throw error;
          }
        } else if (size <= 0 || size > 12 || !Number.isInteger(size))
          throw error;
      });
    }, [childSize]);

    return (
      <div
        ref={ref || null}
        className={clsx(
          classes.flex,
          classes[`f-${direction}`],
          justify ? classes["f-justify-" + justify] : "",
          alignItems ? classes["f-align-" + alignItems] : "",
          className
        )}
        {...rest}
      >
        {childSize.length > 0
          ? React.Children.map(children, (child, i) =>
              child &&
              React.isValidElement(child) &&
              childSize[i] &&
              child.props
                ? React.cloneElement(child, {
                    className: clsx(
                      child.props?.className || "",
                      classes["f-width-" + childSize[i]]
                    ),
                  })
                : child
            )
          : children}
      </div>
    );
  }
);

Flex.propTypes = {
  direction: PropTypes.oneOf(["row", "col"]),
  justify: PropTypes.oneOf([
    "start",
    "stretch",
    "evenly",
    "around",
    "between",
    "flex-start",
    "center",
    "flex-end",
    "end",
  ]),
  alignItems: PropTypes.oneOf([
    "start",
    "stretch",
    "evenly",
    "around",
    "between",
    "flex-start",
    "center",
    "flex-end",
    "end",
  ]),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  childSize: PropTypes.array,
};

export default Flex;
