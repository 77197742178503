import { Button } from "@material-ui/core";
import React from "react";

const InvalidLink = function () {
  return (
    <div className="flex flex-center flex-middle w-100 h-100vh">
      <div className="flex flex-column flex-center flex-middle">
        {/* <img className="mb-32" src="/assets/images/illustrations/404.svg" alt="" /> */}
        <div className="font-size-24 font-weight-bold py-28">
          The link is invalid, ask the Partner to share the link again
        </div>
        <Button
          className="capitalize"
          variant="contained"
          color="primary"
          onClick={() => null}
        >
          Resend the Link
        </Button>
      </div>
    </div>
  );
};

export default InvalidLink;
