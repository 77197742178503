import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { setUserData } from "../redux/actions/UserActions";
import { getItem } from "../services/localStorageService";
import { authUser } from "../constants/auth";

class Auth extends Component {
  constructor(props) {
    super(props);
    this.props.setUserData(getItem(authUser));
    this.props.setUserData(getItem("userInfo"));
  }

  render() {
    const { children } = this.props;
    return <>{children}</>;
  }
}

export default connect(null, { setUserData })(Auth);
