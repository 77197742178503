import { CUSTOMER, PARTNER, USER } from "app/constants/userType";
import { useHistory } from "react-router-dom";

const AuthCheck = ({ children }) => {
  const token = JSON.parse(localStorage.getItem("auth-user") || null);
  const userType = localStorage.getItem("userType");
  console.log(userType, "usertype");
  const history = useHistory();

  const offlineUrls = ["/session/signin"];
  const partnerUrls = ["/partner-view"];
  const customerUrls = [
    "/connectPage",
    "sessions-defended",
    "/help",
    "/incident-viewer",
    "/dashboard",
    "/summary-report",
    "/offlineMode",
    "/purchase-license",
    "/allow-deny-list",
  ];
  const pathname = window.location.pathname;

  if (!token && offlineUrls.includes(pathname)) {
    return children;
  }

  if (!token && !offlineUrls.includes(pathname)) {
    const searchParams = new URLSearchParams(window.location.search);

    const redirect_url = searchParams.get("redirect-url");

    console.log(redirect_url, "redirect_url");
    const login_redirect_url = redirect_url
      ? `/session/signin?redirect-url=${redirect_url}`
      : "/session/signin";
    history.push(login_redirect_url);
    return children;
  }

  if (offlineUrls.includes(pathname)) {
    history.push("/summary-report");
  }

  if (pathname === "/") {
    history.push("/summary-report");
  }

  if (partnerUrls.includes(pathname) && [CUSTOMER, USER].includes(userType)) {
    history.push("/summary-report");
  }

  if (customerUrls.includes(pathname) && userType === PARTNER) {
    history.push("/partner-view");
  }

  return children;
};

export default AuthCheck;
