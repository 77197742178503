import { Button } from "@material-ui/core";
import React from "react";

const Home = () => {
  return (
    <div
      style={{
        display: "grid",
        placeItems: "center",
        width: "100vw",
        height: "100vh",
      }}
    >
      <div>
        Sorry, there was problem loading page. Please try again.{" "}
        <Button onClick={() => window.location.reload()}>Refresh</Button>
      </div>
    </div>
  );
};

export default Home;
