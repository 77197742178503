const localStorage = window.localStorage;

export const getItem = (key) => {
  let value = localStorage.getItem(key);
  try {
    return JSON.parse(value);
  } catch (e) {
    return null;
  }
};

export const setItem = (key, value) => {
  const storageValue = JSON.stringify(value);
  localStorage.setItem(key, storageValue);
  return true;
};

export const removeItem = (key) => {
  localStorage.removeItem(key);
  return true;
};

export const clearStorage = () => {
  localStorage.clear();
  return true;
};

class localStorageService {
  ls = window.localStorage;

  setItem(key, value) {
    value = JSON.stringify(value);
    this.ls.setItem(key, value);
    return true;
  }

  getItem(key) {
    let value = this.ls.getItem(key);
    try {
      return JSON.parse(value);
    } catch (e) {
      return null;
    }
  }

  removeItem() {
    return true;
  }
}

export default new localStorageService();
