import SignUp from "./sign-up";
import SignIn from "./sign-in";
import NotFound from "./NotFound";
import ForgotPassword from "./ForgotPassword";
import InviteLogin from "./invitee";
import InvalidLink from "./invalidLink";

const settings = {
  activeLayout: "layout1",
  layout1Settings: {
    topbar: {
      show: false,
      showcontent: true,
    },
    leftSidebar: {
      show: false,
      mode: "close",
    },
  },
  layout2Settings: {
    mode: "full",
    topbar: {
      show: false,
    },
    navbar: { show: false },
  },
  secondarySidebar: { show: false },
  footer: { show: false },
};

const sessionRoutes = [
  {
    path: "/session/SignUp",
    component: SignUp,
    settings,
  },
  {
    path: "/session/signin",
    component: SignIn,
    settings,
  },
  {
    path: "/session/forgot-password",
    component: ForgotPassword,
    settings,
  },
  {
    path: "/session/404",
    component: NotFound,
    settings,
  },
  {
    path: "/session/inviteeLogin/404",
    component: InvalidLink,
    settings,
  },
  {
    path: "/session/inviteeLogin",
    component: InviteLogin,
    settings,
  },
];

export default sessionRoutes;
