import {
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Modal from "../partitions/modal";
import useSignUp from "./hooks";
import cssclasses from "../session.module.css";
import clsx from "clsx";
import Flex from "app/components/Flex/Flex";

const SignUp = function (props) {
  const {
    handleChange,
    handleFormSubmit,
    username,
    email,
    password,
    withOtp,
    setWithOtp,
    loading,
    handleResendOTP,
    isResendingOtp,
    isOpen,
    handleClose,
    handleSubmit,
    value,
    handleChangeOptionChange,
    withEmail,
    withoutEmail,
    showHavingDifficulty,
    handleCountryChange,
    mobileNumber,
    countryCode,
    companyName,
    allCountries,
    country,
    handleSelect,
  } = useSignUp(props);

  return (
    <div className="signup flex flex-center w-100 h-100vh">
      <div className="p-8">
        <Card className="signup-card position-relative y-center">
          <Grid container>
            <Grid item lg={5} md={5} sm={5} xs={12}>
              <div className="p-32 flex flex-center bg-light-gray flex-middle h-100">
                <img src="/assets/images/assertion.png" alt="" />
              </div>
            </Grid>
            <Grid item lg={7} md={7} sm={7} xs={12}>
              {withOtp && (
                <div className="px-24">
                  <IconButton
                    onClick={() => {
                      setWithOtp(false);
                    }}
                  >
                    <i className="fa fa-arrow-circle-left mg-t-20 font-size-22" />
                  </IconButton>
                </div>
              )}
              <div
                className={
                  withOtp
                    ? "px-36 pb-40 flex flex-center flex-middle h-100"
                    : "p-36 flex flex-center flex-middle h-100"
                }
              >
                <ValidatorForm onSubmit={handleFormSubmit}>
                  {!withOtp && (
                    <TextValidator
                      className={clsx("mb-24 w-100", cssclasses.input_width)}
                      variant="outlined"
                      label="Name"
                      onChange={handleChange}
                      type="text"
                      name="username"
                      value={username}
                      validators={["required"]}
                      errorMessages={["this field is required"]}
                    />
                  )}
                  <TextValidator
                    className={clsx("mb-24 w-100", cssclasses.input_width)}
                    variant="outlined"
                    label="Email"
                    onChange={handleChange}
                    type="email"
                    name="email"
                    value={email}
                    validators={["required", "isEmail"]}
                    errorMessages={[
                      "this field is required",
                      "email is not valid",
                    ]}
                    disabled={withOtp}
                  />
                  {!withOtp && (
                    <Autocomplete
                      id="country-select-demo"
                      inputValue={country || ""}
                      className="w-100 mb-20"
                      options={allCountries}
                      onInputChange={handleCountryChange}
                      value={country}
                      autoHighlight
                      getOptionLabel={(option) => option.label}
                      renderOption={(option) => (
                        <>
                          {option.label} ({option.code}) +{option.phone}
                        </>
                      )}
                      onSelect={handleSelect}
                      renderInput={(params) => (
                        <TextValidator
                          {...params}
                          variant="outlined"
                          label="Choose a country"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                          }}
                          value={country}
                          errorMessages={["this field is required"]}
                        />
                      )}
                    />
                  )}
                  {!withOtp && (
                    <>
                      <Flex>
                        <TextValidator
                          className={cssclasses.country_code}
                          variant="outlined"
                          name="countryCode"
                          value={countryCode}
                          type="text"
                        />
                        <TextValidator
                          className={clsx(
                            cssclasses.country_mobile_number,
                            "ml-10"
                          )}
                          variant="outlined"
                          name="mobileNumber"
                          label="Phone Number"
                          onChange={handleChange}
                          value={mobileNumber}
                          type="number"
                        />
                      </Flex>
                      <div className="m-10">
                        Verification code will be sent to this number
                      </div>
                    </>
                  )}
                  {!withOtp && (
                    <TextValidator
                      className={clsx("mb-24 w-100", cssclasses.input_width)}
                      variant="outlined"
                      label="Company name"
                      onChange={handleChange}
                      type="text"
                      name="companyName"
                      value={companyName}
                      validators={["required"]}
                      errorMessages={["this field is required"]}
                      disabled={withOtp}
                    />
                  )}
                  {withOtp && (
                    <TextValidator
                      className={clsx("mb-24 w-200", cssclasses.input_width)}
                      variant="outlined"
                      label="Verification Code"
                      onChange={handleChange}
                      type="password"
                      name="password"
                      value={password}
                      validators={["required"]}
                      errorMessages={["this field is required"]}
                    />
                  )}
                  <div className="flex flex-middle mb-8">
                    <Button
                      className="capitalize"
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={loading || isResendingOtp}
                    >
                      {loading && (
                        <CircularProgress size={24} className="ml-5 mr-8" />
                      )}
                      {withOtp ? "Verify Code" : "Sign up"}
                    </Button>
                    <div>
                      {!withOtp && <span className="ml-16 mr-8">or</span>}
                      <Button
                        className="capitalize"
                        onClick={
                          !withOtp
                            ? () => props.history.push("/session/signin")
                            : () => {
                                handleResendOTP();
                              }
                        }
                        disabled={loading}
                      >
                        {isResendingOtp && (
                          <CircularProgress size={24} className="ml-5 mr-4" />
                        )}
                        {withOtp ? " Resend Code " : "Sign In"}
                      </Button>
                    </div>
                  </div>
                  {(showHavingDifficulty || withOtp) && (
                    <Flex direction="row-reverse">
                      <Button
                        className="mb-0 ml-2"
                        onClick={() => {
                          handleClose();
                        }}
                      >
                        Having difficulty to sign In ?
                      </Button>
                    </Flex>
                  )}

                  <Modal
                    buttons={withOtp ? withEmail : withoutEmail}
                    isOpen={isOpen}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                    handleChangeOptionChange={handleChangeOptionChange}
                    value={value}
                    title="Having difficulty to Sign Up"
                  />
                </ValidatorForm>
              </div>
            </Grid>
          </Grid>
        </Card>
      </div>
    </div>
  );
};

export default SignUp;
