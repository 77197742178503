import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Icon, Tooltip } from "@material-ui/core";
import TouchRipple from "@material-ui/core/ButtonBase";
import EgretVerticalNavExpansionPanel from "./EgretVerticalNavExpansionPanel";
import { withStyles } from "@material-ui/styles";
import clsx from "clsx";

const styles = () => ({
  expandIcon: {
    transition: "transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
    transform: "rotate(90deg)",
  },
  collapseIcon: {
    transition: "transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
    transform: "rotate(0deg)",
  },
});

class EgretVerticalNav extends Component {
  state = {
    collapsed: true,
  };

  renderLevels = (data, currentPath, onClick) => {
    return data.map((item, index) => {
      if (item.children) {
        return (
          <EgretVerticalNavExpansionPanel item={item} key={index}>
            {this.renderLevels(item.children)}
          </EgretVerticalNavExpansionPanel>
        );
      } else {
        return (
          <Tooltip
            title={item.tooltip || item.icon}
            placement="right"
            arrow
            key={index}
          >
            <NavLink
              key={index}
              to={item.path}
              className={
                currentPath === item.path ? "nav-item ml-12" : "nav-item ml-12"
              }
              onClick={() => {
                onClick(item.name);
              }}
              style={{
                background: currentPath === item.path && item.background,
              }}
            >
              <TouchRipple
                key={item.name}
                name="child"
                className={clsx(
                  "w-100",
                  typeof item.icon === "string" ? "" : "pl-16 pr-16"
                )}
              >
                {(() => {
                  if (item.icon) {
                    if (typeof item.icon === "string") {
                      return (
                        <Icon
                          className={`item-icon text-middle`}
                          style={{
                            color:
                              currentPath === item.path
                                ? item.selectedColor
                                : item.color,
                            fontSize:
                              currentPath === item.path &&
                              item.selectedFontSize,
                          }}
                        >
                          {item.icon}
                        </Icon>
                      );
                    }
                    return currentPath === item.path
                      ? React.cloneElement(item.highlightIcon)
                      : React.cloneElement(item.icon);
                  } else {
                    return (
                      <span className="item-icon icon-text">
                        {item.iconText}
                      </span>
                    );
                  }
                })()}
                <span className="text-middle pl-20 item-text">{item.name}</span>
                <div className="mx-auto"></div>
                {item.badge && (
                  <div className={`badge bg-${item.badge.color}  ml-40`}>
                    {item.badge.value}
                  </div>
                )}
              </TouchRipple>
            </NavLink>
          </Tooltip>
        );
      }
    });
  };

  handleClick = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  render() {
    return (
      <div style={{ position: "absolute", top: "50%" }} className="navigation">
        {this.renderLevels(
          this.props.navigation,
          this.props.currentPath,
          this.props.onClick
        )}
      </div>
    );
  }
}

export default withStyles(styles)(EgretVerticalNav);
