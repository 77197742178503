import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { clearStorage } from "../../../services/localStorageService";
import { linkValidation, verifyInvitee } from "../actions";

const useSignIn = (props) => {
  const { classes } = props;

  const dispatch = useDispatch();

  const [authDetail, setAuthDetail] = useState({
    password: "",
    isMailVerified: false,
  });
  const [withOtp, setWithOtp] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [isVerifying, setIsverifying] = useState(false);
  const [message, setMessage] = useState(false);

  const { password, isMailVerified } = authDetail;

  const inviteCode = props.location.pathname.substring(
    props.location.pathname.lastIndexOf("/") + 1
  );

  useEffect(() => {
    clearStorage();
    setIsverifying(true);
    if (inviteCode) {
      dispatch(linkValidation(inviteCode, setMessage)).then((res) => {
        if (res) {
          setWithOtp(true);
          setIsverifying(false);
        } else {
          props.history.push({
            pathname: "/session/inviteeLogin/404",
            state: { message },
          });
        }
      });
    } else {
      props.history.push("/session/inviteeLogin/404");
    }
  }, []);

  const handleChange = (event) => {
    event.persist();
    const auth = { ...authDetail, [event.target.name]: event.target.value };
    setAuthDetail(auth);
  };

  const handleVerifyOtp = () => {
    setIsLoading(true);
    dispatch(
      verifyInvitee(
        password,
        inviteCode,
        setIsLoading,
        setAuthDetail,
        props.history
      )
    );
  };

  return {
    handleChange,
    handleVerifyOtp,
    password,
    isMailVerified,
    classes,
    withOtp,
    isVerifying,
    isloading,
    message,
  };
};

export default useSignIn;
