import {
  SET_REGISTRATION_DATA,
  SET_SHOW_LOADER,
  SET_HIDE_LOADER,
} from "./actions";

const initialState = {
  userData: { name: "", email: "", isLoggedIn: false },
  displayLoder: false,
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_REGISTRATION_DATA:
      return { ...state, userData: { ...state.userData, ...action.payload } };
    case SET_SHOW_LOADER:
      return { ...state, displayLoder: true };
    case SET_HIDE_LOADER:
      return { ...state, displayLoder: false };
    default:
      return state;
  }
};

export default sessionReducer;
