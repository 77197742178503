import history from "history.js";
import jwtAuthService from "../../services/jwtAuthService";
import { setItem } from "../../services/localStorageService";

export const SET_USER_DATA = "USER_SET_DATA";
export const REMOVE_USER_DATA = "USER_REMOVE_DATA";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const USER_SESSION_KEY = "USER_SESSION_KEY";
export const USER_OPERATIONS_DASHBOARD_DATA = "USER_OPERATIONS_DASHBOARD_DATA";
export const SET_USER_OPERATIONS_DASHBOARD_DURATION =
  "SET_USER_OPERATIONS_DASHBOARD_DURATION";

export function setUserOperDuaration(data) {
  return {
    type: SET_USER_OPERATIONS_DASHBOARD_DURATION,
    data: data,
  };
}

export function setUserData(user) {
  return (dispatch) => {
    dispatch({
      type: SET_USER_DATA,
      data: user,
    });
  };
}

export function setUserOperationsDashboardData(data) {
  return {
    type: USER_OPERATIONS_DASHBOARD_DATA,
    data,
  };
}

export const setUserSessionKey = (key) => ({
  type: USER_SESSION_KEY,
  data: key,
});

export function logoutUser() {
  setItem("auth-user", {});
  return (dispatch) => {
    jwtAuthService.logout();

    history.push({
      pathname: "/session/signin",
    });

    dispatch({
      type: USER_LOGGED_OUT,
    });
  };
}
