export const SHOW_MESSAGE = "/SHOW_MESSAGE";
export const HIDE_MESSAGE = "/HIDE_MESSAGE";

export const showMessage = (data) => (dispatch) => {
  dispatch({
    type: SHOW_MESSAGE,
    payload: data,
  });
};

export const hideMessage = () => (dispatch) => {
  dispatch({
    type: HIDE_MESSAGE,
  });
};
