import { SHOW_MESSAGE, HIDE_MESSAGE } from "../actions/snackbar";

const intialState = {
  data: {
    message: "",
    show: false,
    type: "info",
  },
};

const snackbar = (state = intialState, action) => {
  switch (action.type) {
    case SHOW_MESSAGE: {
      return { ...state, data: { ...action.payload } };
    }
    case HIDE_MESSAGE: {
      return intialState;
    }
    default:
      return state;
  }
};

export default snackbar;
