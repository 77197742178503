import React from "react";
import {
  Card,
  Grid,
  Button,
  withStyles,
  CircularProgress,
} from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { withRouter } from "react-router-dom";
import useSignIn from "./hooks";
import styles from "./styles";

const InviteeLogin = function (props) {
  const {
    handleChange,
    handleVerifyOtp,
    password,
    withOtp,
    classes,

    isVerifying,
    isloading,
    message,
  } = useSignIn(props);
  return (
    <div className="signup flex flex-center w-100 h-100vh">
      <div className="p-8">
        <Card
          className="signup-card position-relative y-center"
          style={{ background: isVerifying ? "#1a2038" : "white" }}
        >
          {isVerifying ? (
            <CircularProgress size={24} className="ml-5 mr-8" />
          ) : (
            <Grid container>
              <Grid item lg={5} md={5} sm={5} xs={12}>
                <div className="p-32 flex flex-center flex-middle h-100">
                  <img src="/assets/images/illustrations/dreamer.svg" alt="" />
                </div>
              </Grid>
              <Grid item lg={7} md={7} sm={7} xs={12}>
                <div className="p-36 h-100 bg-light-gray position-relative">
                  <div style={{ textAlign: "center" }}>
                    <div
                      className="py-16 font-weight-font-weight-font-weight-semi"
                      style={{ color: "#08ad6c" }}
                    >
                      {" "}
                      <i className="fa fa-check-circle" />
                      {message}
                    </div>
                  </div>
                  <ValidatorForm onSubmit={handleVerifyOtp}>
                    {withOtp && (
                      <>
                        <TextValidator
                          className="mb-16 w-100"
                          label="OTP"
                          variant="outlined"
                          onChange={handleChange}
                          name="password"
                          placeholder="Enter OTP"
                          type="password"
                          value={password}
                          validators={["required"]}
                          errorMessages={["this field is required"]}
                          style={{ width: "330px" }}
                        />
                        <div className="flex flex-middle mb-8">
                          <div className={classes.wrapper}>
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              disabled={isloading}
                            >
                              {isloading && (
                                <CircularProgress
                                  size={24}
                                  className="ml-5 mr-8"
                                />
                              )}
                              Verify OTP
                            </Button>
                          </div>
                        </div>
                      </>
                    )}
                  </ValidatorForm>
                </div>
              </Grid>
            </Grid>
          )}
        </Card>
      </div>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(
  withRouter(InviteeLogin)
);
