import { Button, Fab, Icon, Step, StepLabel, Stepper } from "@material-ui/core";
import { EgretProgressBar } from "egret";
import React from "react";
import { useDispatch } from "react-redux";
import { apiEndPoints } from "../apis";
import api from "../apis/api";
import { showMessage } from "../redux/actions/snackbar";
import { bytesToSize } from "../utils/helperFunction";

function getSteps() {
  return ["Download Utility", "Connect", "Upload", "Scan Status"];
}

// function getStepContent(stepIndex) {
//   switch (stepIndex) {
//     case 2:
//       return <h1>Click here to Upload</h1>;
//     default:
//       return "";
//   }
// }

const HorizontalStepper = function (props) {
  const { done_state } = props;
  const dispatch = useDispatch();

  const stateObj = {
    downloaded: 1,
    connected: 2,
    uploaded: 3,
    completed: 4,
  };

  const getActiveIndex = (done_state) => stateObj[done_state];

  const [activeStep, setActiveStep] = React.useState(
    getActiveIndex(done_state)
  );
  const [file, setFile] = React.useState(undefined);
  const [loader, setLoader] = React.useState(false);
  const [uploadProgress, setUploadProgress] = React.useState(0);
  const [error, setError] = React.useState(undefined);

  // const userData = getItem("auth-user");
  // const token = userData.idToken;

  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleUpload = (handleNext, file) => {
    const formData = new FormData();
    formData.append("scanfile", file);

    const config = {
      onUploadProgress(progressEvent) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );

        setUploadProgress(percentCompleted);
      },
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    setLoader(true);
    api
      .post(apiEndPoints.fileUpload, formData, config)
      .then((res) => {
        if (res.data.message === "Unauthorized") {
          window.location = "/session/signin";
        }
        if (res.status === 200) {
          setLoader(false);
          setFile(undefined);
          dispatch(
            showMessage({
              show: true,
              message: "file uploaded successfully",
              type: "success",
            })
          );
          handleNext();
        }
      })
      .catch(() => {
        setError(true);
        setLoader(false);
        setUploadProgress(0);
        setFile(undefined);
      });
  };

  const handleFileSelect = (event) => {
    setError(false);
    const files = event.target.files[0];

    setFile(files);
  };

  return (
    <div>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div style={{ textAlign: "center" }}>
        {activeStep === steps.length ? (
          <div>
            <div className="flex my-16 ml-8">
              <Icon>done</Icon> <span className="ml-8">Scan Completed</span>
            </div>
          </div>
        ) : (
          <div>
            {/* {getStepContent(activeStep)} */}
            {activeStep === 2 && (
              <div className="pt-24">
                {!file && (
                  <>
                    <label htmlFor="upload-single-file">
                      <Fab
                        className="capitalize"
                        color="primary"
                        component="span"
                        variant="extended"
                      >
                        <div className="flex flex-middle">
                          <Icon className="pr-8">cloud_upload</Icon>
                          <span>
                            {file ? "upload" : "select a zip file to upload"}
                          </span>
                        </div>
                      </Fab>
                    </label>
                    <input
                      className="display-none"
                      onChange={handleFileSelect}
                      id="upload-single-file"
                      type={file ? "submit" : "file"}
                    />
                  </>
                )}
                {!loader ? (
                  file && (
                    <Fab
                      className="capitalize"
                      color="primary"
                      component="span"
                      variant="extended"
                    >
                      <div
                        className="flex flex-middle"
                        onClick={() => {
                          handleUpload(handleNext, file);
                        }}
                      >
                        <Icon className="pr-8">cloud_upload</Icon>
                        <span className="ml-12">
                          {`Upload ${file.name}` + ` ${bytesToSize(file.size)}`}
                        </span>
                      </div>
                    </Fab>
                  )
                ) : (
                  <span>
                    <EgretProgressBar value={uploadProgress} />
                    <p>{`${uploadProgress} %`}</p>
                  </span>
                )}
                {error && (
                  <p className="text-error">Failed to Upload the file</p>
                )}
              </div>
            )}
            {activeStep === 1 && (
              <div className="pt-24">
                <Button
                  className="ml-16"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleNext();
                  }}
                >
                  Connect
                </Button>
              </div>
            )}
            {activeStep === 3 && (
              <p className="text-info">Scan in progress...</p>
            )}
            {activeStep === 4 && (
              <p className="text-success">Scan is Completed...</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default HorizontalStepper;
