import {
  SET_USER_DATA,
  REMOVE_USER_DATA,
  USER_LOGGED_OUT,
  USER_SESSION_KEY,
  USER_OPERATIONS_DASHBOARD_DATA,
  SET_USER_OPERATIONS_DASHBOARD_DURATION,
} from "../actions/UserActions";

const initialState = {
  sessionKey: "",
  operationsDashboard: {},
  operationsDashboardDuration: 30,
};

const userReducer = function (state = initialState, action) {
  switch (action.type) {
    case SET_USER_DATA: {
      return {
        ...state,
        ...action.data,
      };
    }

    case SET_USER_OPERATIONS_DASHBOARD_DURATION: {
      return {
        ...state,
        operationsDashboardDuration: action.data,
      };
    }
    case USER_OPERATIONS_DASHBOARD_DATA: {
      return {
        ...state,
        operationsDashboard: action.data,
      };
    }
    case REMOVE_USER_DATA: {
      return {
        ...state,
      };
    }
    case USER_LOGGED_OUT: {
      return state;
    }

    case USER_SESSION_KEY: {
      return { ...state, sessionKey: action.data };
    }
    default: {
      return state;
    }
  }
};

export default userReducer;
