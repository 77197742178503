import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(3),
  },
  group: {
    margin: theme.spacing(1, 0),
  },
}));

const RadioButton = function (props) {
  const { groupTitle, buttons, value, handleChangeOptionChange } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <FormControl component="fieldset">
        <FormLabel component="legend">{groupTitle}</FormLabel>
        <RadioGroup
          aria-label="Gender"
          name="gender1"
          className={classes.group}
          value={value}
          onChange={handleChangeOptionChange}
        >
          {buttons ? (
            buttons.map((d) => (
              <FormControlLabel
                key={d.value}
                style={{ maringTop: 10 }}
                value={d.value}
                control={<Radio />}
                label={d.lable}
              />
            ))
          ) : (
            <FormControlLabel value="male" control={<Radio />} label="Male" />
          )}
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default RadioButton;
