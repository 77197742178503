import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { hideMessage } from "../../redux/actions/snackbar";

const useStyles2 = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

const snackBarData = ({ snackbar }) => snackbar.data;

const useSnackbar = () => {
  const dispatch = useDispatch();

  const classes = useStyles2();

  const snackBarState = useSelector(snackBarData);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(hideMessage());
  };

  return {
    ...snackBarState,
    handleClose,
    classes,
  };
};

export default useSnackbar;
