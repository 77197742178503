const firebaseConfig = {
  apiKey: "YOUR API KEY",
  authDomain: "AUTH DOMAIN",
  databaseURL: "DATABASE URL",
  projectId: "PROJECT ID",
  storageBucket: "STORAGE BUCKET",
  messagingSenderId: "MESSAGING SENDER ID",
  appId: "APP ID",
};

export default firebaseConfig;
