import React from "react";
import classes from "./mobile.module.css";
import mobile from "./images/mobile.png";
import chrome from "./images/chrome.png";

const Mobile = () => {
  return (
    <div className={classes.wrap}>
      <p className={classes.title}>Unsupported browser</p>
      <img src={mobile} />
      <p className={classes.subtitle}>
        Please use Google Chrome or Microsoft edge on computer to view
      </p>
      <img src={chrome} />
      <p className={classes.text}>Google Chrome</p>
    </div>
  );
};

export default Mobile;
